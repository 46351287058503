<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 one-click-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="one-click-header icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: 'r_one-click-settings' }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title" v-if="widgets && widgets.length">
          {{ widgets.find(el => el.widget_id === 4).name }}
        </div>
        <template v-slot:right>
          <router-link :to="{ name: 'r_one-click-info-add' }">
            <icon icon="#cx-hea1-add" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
    </template>
    <Draggable
      v-if="info && info.length && mode !== 'selection'"
      :list="info"
      item-key="id"
      tag="ul"
      @end="saveInfo"
      class="clebex-item-section pill"
    >
      <template #item="{element}">
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt w-100">
                <router-link
                  :to="{
                    name: 'r_one-click-info-edit',
                    params: { infoId: element.id }
                  }"
                  class="w-100"
                >
                  {{ element.name }}
                  <icon
                    icon="#cx-app1-arrow-right-12x12"
                    width="12"
                    height="12"
                  />
                </router-link>
              </dt>
            </dl>
          </div>
        </li>
      </template>
    </Draggable>
    <Draggable
      v-if="info && info.length && mode === 'selection'"
      :list="info"
      item-key="id"
      tag="ul"
      @end="saveInfo"
      class="clebex-item-section pill"
    >
      <template #item="{element}">
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="checkbox tiny alt">
              <input
                type="checkbox"
                :id="`resource${element.id}`"
                name="resource"
                :selected="selectedInfo.includes(element.id)"
                :value="element.id"
                @change="selectInfo(element.id)"
              />
              <label :for="`resource${element.id}`">
                <icon icon="#cx-app1-checkmark" />
              </label>
            </div>
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <button class="cursor-default">
                  {{ element.name }}
                </button>
              </dt>
            </dl>
          </div>
        </li>
      </template>
    </Draggable>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list" v-if="info && info.length">
          <li class="action">
            <button
              class="action-btn"
              @click="setMode(mode === 'selection' ? null : 'selection')"
              :class="{ active: mode === 'selection' }"
            >
              {{ displayLabelName("one-click", "info", "select") }}
            </button>
          </li>
          <li
            v-if="mode === 'selection' && selectedInfo.length && !processing"
            class="action"
          >
            <button class="action-btn" @click="showDeleteModal = true">
              <icon icon="#cx-app1-delete-02-14x14" />
            </button>
            <span class="action-btn deleted-count">{{
              "(" + selectedInfo.length + "/" + info.length + ")"
            }}</span>
          </li>
        </ul>
      </nav>
    </template>
    <screen-modal
      v-if="showDeleteModal"
      class="confirm-modal delete-modal"
      type="success"
      :confirm-action="startDelete"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancelButtonLabel="displayLabelName('global.buttons.cancel')"
      :show="showDeleteModal"
      @close="showDeleteModal = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("one-click.info.delete-title") }}
      </h3>
      <p class="text">
        {{ displayLabelName("one-click.info.delete-action-text") }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions } from "vuex";

export default {
  name: "OneClickInfo",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    Draggable: defineAsyncComponent(() => import("vuedraggable"))
  },
  created() {
    this.getInfo();
  },
  data() {
    return {
      processing: false,
      selectedInfo: [],
      mode: null,
      showDeleteModal: false
    };
  },
  computed: {
    ...mapState("oneClick", ["widgets", "info"])
  },
  methods: {
    ...mapActions("oneClick", ["getInfo", "setInfoPositions", "deleteInfo"]),
    saveInfo() {
      let postArray = [];
      let ordering = 1;
      this.info.forEach(element => {
        postArray.push({
          id: element.id,
          ordering: ordering
        });
        ordering++;
      });
      this.setInfoPositions(postArray);
    },
    selectInfo(id) {
      if (this.selectedInfo.find(element => element === id)) {
        this.selectedInfo = this.selectedInfo.filter(element => element !== id);
      } else {
        this.selectedInfo.push(id);
      }
    },
    setMode(mode) {
      this.mode = mode;
    },
    startDelete() {
      const promises = [];
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      this.selectedInfo.forEach(id => {
        this.processing = true;
        const promise = new Promise((resolve, reject) => {
          this.deleteInfo(id)
            .then(() => {
              resolve(true);
            })
            .catch(() => {
              reject(false);
            });
        });
        promises.push(promise);
      });
      Promise.all(promises).then(() => {
        this.processing = false;
        this.mode = null;
        this.selectedInfo = [];
        this.$store.commit("loader/setScreenLoading", false, { root: true });
      });
    }
  }
};
</script>
<style>
.deleted-count {
  position: relative;
  top: -4px;
  margin: 5px 8px;
  padding-left: 0px !important;
  margin-left: 0px;
}
</style>
